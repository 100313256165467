import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../../styles/media'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { FlexCenter } from '../CssTools'


const StyledWrap = styled.div`
  width:100%;
  ${FlexCenter}
  flex-flow:column nowrap;
  padding:0 16px;
  p{
    font-weight:800;
    font-size:1.12em;
  }
  ${() => media.tablet(css`
    max-width:500px;
    margin:0 auto;
  `)}

`

const DoubleLogo = () => {
  const data = useStaticQuery(query)
  return (
    <StyledWrap>
      <StaticImage
        src='../../images/doubleLogo.jpg'
        placeholder='none'
        alt='フランソアと帝人'
      />
      <p>{data.text.withTeijin.imgtxt[0]}</p>
    </StyledWrap>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: {eq: "laboPage"}) {
      withTeijin {
        imgtxt
      }
    }
  }
`

export default DoubleLogo
