/**
 * Third-party libraries
 */
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
/**
 * First-party files
 */
// Components
import DoubleLogo from "components/imageComps/DoubleLogo"
import Layout from "components/Layout"
import { ThirdLayer, Main } from "components/ThirdLayerBody"
import ThirdLayerClose from "components/ThirdLayerClose"
import ThirdLayerHeader from "components/ThirdLayerHeader"
import SwipeMotionDiv from "components/ui/SwipeMotionDiv"
import SEO from '../../components/SEO'

const WithTeijin = props => {
  const data = useStaticQuery(query)
  const { location, transitionStatus, entry, exit } = props
  const parentPath = "/" + location.pathname.split("/")[1]

  const jsonld = [
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${data.seo.top.pass}`,
          "name": `${data.seo.top.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${data.seo.page.labo.pass}`,
          "name": `${data.seo.page.labo.title}`,
        }
      }, {
        "@type": "ListItem",
        "position": 3,
        "item": {
          "@id": `${data.seo.page.labo.withTeijin.pass}`,
          "name": `${data.seo.page.labo.withTeijin.title}`,
        }
      }]
    }
  ]

  return (
    <ThirdLayer>
      <Layout>
        <SEO
          title={data.seo.page.labo.withTeijin.title}
          pageUrl={data.seo.page.labo.withTeijin.pass}
          description={data.seo.page.labo.withTeijin.description}
          imgUrl='/mainVisual.jpg'
          type='article'
          jsonld={jsonld}
        />
        <SwipeMotionDiv
          entry={entry}
          exit={exit}
          transitionStatus={transitionStatus}
        >
          <ThirdLayerHeader
            text={data.text.withTeijin.leadText}
            parentPath={parentPath}
          />
          <Main>
            <p>{data.text.withTeijin.paragraph1}</p>
            <p>{data.text.withTeijin.paragraph2}</p>
            <p>{data.text.withTeijin.paragraph3}</p>
            <DoubleLogo />
          </Main>
          <ThirdLayerClose parentPath={parentPath} />
        </SwipeMotionDiv>
      </Layout>
    </ThirdLayer>
  )
}

export const query = graphql`
  {
    text: pagesYaml(pageName: { eq: "laboPage" }) {
      withTeijin {
        leadText
        paragraph1
        paragraph2
        paragraph3
      }
      bacterialFlora {
        leadText
        paragraph1
        paragraph2
        paragraph3
      }
      varietyOfFiber {
        leadText
        paragraph1
        paragraph2
        paragraph3
      }
    }
    lead: componentsYaml {
      laboCard {
        leadText
      }
      bitsOfKnowledgeCard {
        leadText
      }
      thirtyYearsCard {
        leadText
      }
    }
    seo: dataYaml {
      top {
        description
        pass
        title
      }
      page {
        labo {
          description
          pass
          title
          withTeijin {
            description
            pass
            title
          }
        }
      }
    }
  }
`

export default WithTeijin
